import Foot from "../companent/foot";
import Appbar from "../companent/appbar";
import { Box } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function Page2() {
    const [age, setAge] = React.useState("");
    // ฟังก์ชันเมื่อเลือกค่าใน Select
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    // ฟังก์ชันเมื่อกดปุ่ม Submit
    const Button_click = () => {
        alert(`${age}`);
    };

    return (
        <div className="App">
            <Appbar />
            <header className="App-header">
                <Box>
                    <Card sx={{ maxWidth: 500, m: 3 }}>
                        <CardMedia
                            sx={{ height: 200 }}
                            image="https://www.shutterstock.com/image-photo/podarcis-siculus-lizar-nature-260nw-1787175410.jpg"
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Lizard
                            </Typography>
                            <Box sx={{ minWidth: 400 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Age"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="success" onClick={Button_click}>
                                Submit
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
                <p>{age}</p>
            </header>
            <Foot />
        </div>
    )


}

export default Page2;